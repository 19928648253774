import { MutationResult, QueryResult } from "@apollo/client";

export enum QueryState {
  NOT_STARTED,
  IN_PROGRESS,
  FINISHED,
  ERRORED,
}

export function getQueryState<TOperation, TOperationVariables = void>(
  result:
    | QueryResult<TOperation, TOperationVariables>
    | MutationResult<TOperation>
) {
  if (!result.called) return QueryState.NOT_STARTED;
  if (result.loading) return QueryState.IN_PROGRESS;
  if (result.error) return QueryState.ERRORED;
  return QueryState.FINISHED;
}
