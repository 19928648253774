const WebContentContainer = {
  baseStyle: {
    h1: {
      fontSize: "32px",
      fontWeight: "bolder",
      mb: "4",
    },
    h2: {
      fontSize: "24px",
      fontWeight: "bolder",
      mb: "4",
    },
    h3: {
      fontSize: "18px",
      fontWeight: "bolder",
      mb: "4",
    },
    h4: {
      fontSize: "16px",
      fontWeight: "bolder",
      mb: "4",
    },
    strong: {
      fontWeight: "bolder",
    },
    b: {
      fontWeight: "bolder",
    },
    p: {
      mb: "4",
    },
    a: {
      color: "blue.700",
    },
    img: {
      maxWidth: "100%",
      height: "auto",
      margin: "auto",
    },
    li: {
      ml: "4",
    },
  },
};

export default WebContentContainer;
