import {
  Box,
  Flex,
  Select,
  Image,
  ChakraProvider,
  SlideFade,
  Center,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { gql, useQuery } from "@apollo/client";
import getApolloClient from "apollo/client";
import {
  BackgroundDarkening,
  GetLoginpageDataQuery,
  ThemeName,
} from "apollo/generatedTypes";
import { sortBy } from "lodash-es";
import React, { useState } from "react";
import { getLanguageFriendlyName } from "@eam/incentive-shared/src/languages";
import { useIntl } from "react-intl";
import BackgroundImage from "./BackgroundImage";
import ThemeSelectDropdown from "./ThemeSelectDropdown";
import classicTheme from "styles/theme";
import allSharesTheme from "styles/theme2";
import { QueryState, getQueryState } from "./queryUtil";
import SlideFadeSpinner from "./SlideFadeSpinner";

const apolloClient = getApolloClient();

const GET_LOGIN_PAGE_DATA = gql`
  query getLoginpageData($companyBaseUrl: String) {
    getCompany(companyBaseUrl: $companyBaseUrl) {
      id
      logo
      baseUrl
      backgroundDarkening
      themeName
    }
    getSupportedLanguages
  }
`;

const LoginLayout = ({
  children,
  selectedLocale,
  handleLocale,
}: {
  children: React.ReactNode;
  selectedLocale: string;
  handleLocale: (locale: string) => void;
}) => {
  const router = useRouter();
  const { company } = router.query;

  const payload = useQuery<GetLoginpageDataQuery>(GET_LOGIN_PAGE_DATA, {
    variables: {
      companyBaseUrl: company ? encodeURIComponent(String(company)) : undefined,
    },
    client: apolloClient,
    errorPolicy: "all",
    onCompleted: (data) => {
      handleTheme(data?.getCompany?.themeName as ThemeName);
    },
  });

  const { data } = payload;
  const { logo, backgroundDarkening, themeName } = data?.getCompany ?? {};
  const { getSupportedLanguages } = data ?? {};
  const { formatMessage } = useIntl();

  const [selectedTheme, setTheme] = useState(
    themeName === ThemeName.Allshares ? allSharesTheme : classicTheme
  );

  const handleTheme = (theme: ThemeName) => {
    if (theme === ThemeName.Allshares) {
      setTheme(allSharesTheme);
    } else {
      setTheme(classicTheme);
    }
  };

  const loginPageDataQueryState = getQueryState(payload);

  if (loginPageDataQueryState !== QueryState.FINISHED) {
    return (
      <ChakraProvider theme={selectedTheme}>
        <Center mt="80px">
          <SlideFadeSpinner isVisible={true} />
        </Center>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={selectedTheme}>
      <SlideFade in={loginPageDataQueryState === QueryState.FINISHED}>
        <Box height="100%" minHeight="100vh">
          <BackgroundImage
            company={company as string}
            backgroundDarkening={backgroundDarkening as BackgroundDarkening}
            isLogin={true}
          />
          <Flex
            alignItems="center"
            background="white"
            height="70px"
            justifyContent="space-between"
            p="6"
            position="sticky"
            top="0"
            zIndex="2"
            borderBottom="1px solid"
            borderBottomColor="gray.200"
          >
            <Flex position="relative" justifyContent="flex-start">
              {logo && (
                <Image
                  height="30px"
                  src={`data:image/png;base64, ${logo}`}
                  alt="logo"
                  objectFit="contain"
                />
              )}
            </Flex>

            <Flex justifyContent="flex-end" gap="2">
              <ThemeSelectDropdown
                handleTheme={handleTheme}
                apolloClient={apolloClient}
              />

              <Select
                value={selectedLocale}
                onChange={(e) => {
                  handleLocale(e.target.value);
                }}
              >
                {sortBy(getSupportedLanguages).map((language) => (
                  <option value={language} key={language}>
                    {formatMessage(getLanguageFriendlyName(language))}
                  </option>
                ))}
              </Select>
            </Flex>
          </Flex>
          <Box zIndex="1" position="relative" height="calc(100vh - 70px)">
            {children}
          </Box>
        </Box>
      </SlideFade>
    </ChakraProvider>
  );
};

LoginLayout.defaultProps = {
  noAuthNeed: true,
};
export default LoginLayout;
