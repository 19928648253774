import { Box, useTheme } from "@chakra-ui/react";
import { BackgroundDarkening } from "apollo/generatedTypes";
import Image from "next/image";
import { useState } from "react";

const BackgroundImage = ({
  company,
  backgroundDarkening,
  isLogin,
}: {
  company?: string;
  backgroundDarkening?: BackgroundDarkening;
  isLogin?: boolean;
}) => {
  const bgImage = company
    ? `/api/download/backgroundimage?filePath=/images/${company}/background.jpg`
    : "/images/bg_generic.jpg";

  const [background, handleBackground] = useState(bgImage);

  const theme = useTheme();

  const backgroundColor = isLogin
    ? theme?.colors?.login?.background
    : theme?.colors?.app?.background;

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex="0"
      background={backgroundColor ? backgroundColor : "primary.dark"}
    >
      {!theme?.colors?.app?.background && (
        <Image
          src={background}
          onError={() => {
            // This is triggered if backgroundimage api returns an error (usually 404)
            handleBackground("/images/bg_generic.jpg");
          }}
          fill={true}
          style={{
            objectFit: "cover",
            objectPosition: "center",
            filter: backgroundDarkening
              ? backgroundDarkening === BackgroundDarkening.Light
                ? `brightness(70%)`
                : `brightness(40%)`
              : "none",
          }}
          alt=""
          className="app_background"
          priority={true}
        />
      )}
    </Box>
  );
};

export default BackgroundImage;
