import { FormattedMessage } from "react-intl";
import { Button, ButtonProps, useClipboard } from "@chakra-ui/react";
import { useEffect } from "react";

interface ClipBoardProps {
  value: string;
}

const CopyToClipBoardButton = (props: ButtonProps & ClipBoardProps) => {
  const { value, ...rest } = props;
  const { hasCopied, onCopy, setValue } = useClipboard(value);

  useEffect(() => {
    setValue(value);
  }, [setValue, value]);

  return (
    <Button onClick={onCopy} {...rest}>
      {hasCopied ? (
        <FormattedMessage id="common.copied" defaultMessage="Copied" />
      ) : (
        <FormattedMessage id="common.copy" defaultMessage="Copy" />
      )}
    </Button>
  );
};

export default CopyToClipBoardButton;
