const Checkbox = {
  baseStyle: {
    control: {
      background: "white",
      border: "1px",
      borderColor: "blue.300",
      borderRadius: "3",
      _checked: {
        background: "blue.500",
      },
    },
  },
};

export default Checkbox;
