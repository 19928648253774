import { Box, Center, SlideFade, Spinner } from "@chakra-ui/react";

const SlideFadeSpinner = ({
  isVisible,
  children,
  spinnerColor = "brand.heading1",
  maxWidth,
}: {
  isVisible: boolean;
  children?: React.ReactNode;
  spinnerColor?: string;
  maxWidth?: string;
}) => {
  return (
    <SlideFade in={isVisible}>
      <Box
        maxHeight={isVisible ? "none" : 0}
        overflow="hidden"
        maxWidth={maxWidth ?? "auto"}
      >
        {children ? (
          children
        ) : (
          <Center p="12">
            <Spinner color={spinnerColor ?? "brand.heading1"} />
          </Center>
        )}
      </Box>
    </SlideFade>
  );
};

export default SlideFadeSpinner;
