const Select = {
  variants: {
    outline: {
      field: {
        borderColor: "input.border",
        background: "input.background",
        _hover: {
          color: "gray.700",
          background: "input.hoverBackground",
        },
      },
    },
  },
  defaultProps: {
    variant: "outline",
  },
};

export default Select;
