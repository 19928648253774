import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const { GRAPHQL_API_URL } = process.env;

const getApolloClient = () => {
  const uri =
    typeof window !== "undefined"
      ? "/api/graphql"
      : GRAPHQL_API_URL ?? "http://localhost:3001/api/graphql";

  const httpLink = new HttpLink({
    credentials: "same-origin",
    uri,
  });

  const errorLink = onError((errors) => {
    const { graphQLErrors, networkError } = errors;
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    } else if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const link = ApolloLink.from([errorLink, httpLink]);

  const cache = new InMemoryCache({});
  const apolloClient = new ApolloClient({
    link,
    cache,
  });
  return apolloClient;
};

export default getApolloClient;
