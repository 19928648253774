import { graphql } from "msw";
// @ts-ignore
import cookieSession from "micro-cookie-session";
import { v4 as uuid } from "uuid";

import {
  LoginMutation,
  LoginMutationVariables,
  GetInitialDataQuery,
  SsoLoginMutation,
  SsoLoginMutationVariables,
  LogoutMutation,
  StopSavingsMutation,
  GetCommissionForInstrumentQuery,
  GetCommissionForInstrumentQueryVariables,
  CommissionInput,
  SignicatLoginMutation,
  SignicatLoginMutationVariables,
  GetPostLoginStepsQuery,
} from "apollo/generatedTypes";
import jwtDecode from "jwt-decode";
import { ApolloError } from "apollo-server-errors";
import { random } from "lodash/fp";

const createSession = cookieSession({
  name: "session",
  keys: ["secret"],
  maxAge: 24 * 60 * 60 * 1000,
});

const generateSessionData = () => {
  return {
    token:
      "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMTQ0ODQ2IiwidHlwZSI6IkFVVEgiLCJhdXRoZW50aWNhdGlvblN0YXR1cyI6IkFVVEhFTlRJQ0FURUQiLCJpYXQiOjE2NDE5MTA2MDAsImV4cCI6OTY0MTkxNDIwMH0.QeYGoafYr2Xu8-tAe9Mtw-yPy_IZ3OTdstIlGUY2Mus",
    sub: "1144846",
    expires: 9641914200,
    authenticated: true,
    hasPostLoginSteps: false,
    uuid: uuid(),
    companyData: {
      id: 1100009,
      name: "Nokia Corporation",
      param1Name: "Param1Test",
      param2Name: "Param2",
    },
    companyBaseUrl: "/nokia",
    permissions: [
      "HOLDER_ACCOUNT_STATEMENT",
      "HOLDER_AWARDS",
      "HOLDER_CREATE_MAR_NOTIFICATION",
      "HOLDER_EVLI_CORPORATE_ACTIONS",
      "HOLDER_EVLI_CUSTODY",
      "HOLDER_EVLI_REPORTS",
      "HOLDER_EVLI_TRANSACTIONS",
      "HOLDER_FAQ",
      "HOLDER_HELP",
      "HOLDER_HISTORY",
      "HOLDER_INCOME_STATEMENT",
      "HOLDER_MAR_NOTIFICATIONS",
      "HOLDER_MAR_STATUS",
      "HOLDER_PARTICIPANT_ACCOUNT_STATEMENT",
      "HOLDER_PERSONNEL_FUNDS",
      "HOLDER_REPORTS",
      "HOLDER_SAVINGS",
      "HOLDER_SAVINGS_PLAN_STATEMENT",
      "HOLDER_SHARE_INFO",
      "HOLDER_SHARE_PURCHASES",
      "HOLDER_SHARE_TRANSFERS",
      "HOLDER_TRANSACTIONS",
      "HOLDER_TRANSACTION_STATEMENT",
      "HOLDER_DEFERRED_INSTRUMENTS",
      "HOLDER_AUTHENTICATION_CODES",
      "HOLDER_MY_OWNERSHIP",
    ],
  };
};

// Create a mock session generator function. Match return value structure to what generateSessionData returns.

const sleep = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const handlers = [
  graphql.query<
    GetCommissionForInstrumentQuery,
    GetCommissionForInstrumentQueryVariables
  >("getCommissionForInstrument", async (req, res, ctx) => {
    const commissionInput: CommissionInput =
      req.body?.variables?.commissionInput;
    const quantity = commissionInput?.sellQuantity;

    return res(
      ctx.data({
        getCommissionForInstrument: quantity > 3 ? 42 : 20,
      })
    );
  }),
  graphql.mutation<StopSavingsMutation>(
    "stopSavings",
    async (req, res, ctx) => {
      await sleep(random(1000, 3000));
      const instrumentId = req.body?.variables?.instrumentId;
      if (instrumentId === 42) {
        throw new ApolloError("An error occurred when trying to stop savings");
      }
      return res(ctx.data({ stopSavings: false }));
    }
  ),
  graphql.query("getPermissions", (req, res, ctx) => {
    try {
      const token: {
        authenticated?: boolean;
        uuid: string;
        permissions: any;
      } = jwtDecode(req.cookies.session, { header: true });

      if (token.authenticated) {
        return res(
          ctx.data({
            getPermissions: token.permissions,
          })
        );
      }
    } catch (err) {
      console.log("E", err);
    }

    return res(ctx.data({ getPermissions: [] }));
  }),
  graphql.query("getInitialUserData", (req, res, ctx) => {
    try {
      const token: {
        authenticated?: boolean;
        uuid: string;
        permissions: any;
      } = jwtDecode(req.cookies.session, { header: true });

      if (token.authenticated) {
        return res(
          ctx.data({
            getPermissions: token.permissions,
          })
        );
      }
    } catch (err) {
      console.log("E", err);
    }

    return res(ctx.data({ getPermissions: [] }));
  }),
  graphql.query<GetInitialDataQuery>("getInitialData", (req, res, ctx) => {
    try {
      const token: {
        authenticated?: boolean;
        uuid: string;
      } = jwtDecode(req.cookies.session, { header: true });

      if (token.authenticated) {
        return res(
          ctx.data({
            sessionId: token.uuid,
          })
        );
      }
    } catch (err) {
      console.error("PARSING TOKEN FAILED", err, req.cookies.session);
    }

    return res(
      ctx.data({
        sessionId: null,
      })
    );
  }),
  graphql.query<GetPostLoginStepsQuery>(
    "getPostLoginSteps",
    (req, res, ctx) => {
      return res(
        ctx.data({
          getPostLoginSteps: {
            steps: [],
          },
          // Turn on all post login steps for testing purposes
          // getPostLoginSteps: {
          //   steps: Object.values(LoginStepType).map((stepType, index) => ({
          //     stepType,
          //     enrolmentType: EnrolmentType.Default,
          //     rowId: index,
          //     skippable: true,
          //   })),
          // },
        })
      );
    }
  ),

  graphql.mutation<LogoutMutation>("logout", (req, res, ctx) => {
    req.cookies.session = "";
    return res(
      ctx.cookie("session", ""),
      ctx.data({
        logout: true,
      })
    );
  }),
  graphql.mutation<LoginMutation, LoginMutationVariables>(
    "login",
    (req, res, ctx) => {
      const { username, password } = req.variables ?? {};

      if (username === "mock" && password === "user") {
        const sessionData = generateSessionData();

        createSession(req, res);
        const { session }: any = req;

        Object.keys(sessionData).forEach((key) => {
          session[key] = (sessionData as any)[key];
        });

        const stringSession = Buffer.from(JSON.stringify(session))
          .toString("base64")
          .replace("=", "");
        return res(
          ctx.cookie("session", stringSession),
          ctx.delay(),
          ctx.data({
            login: {
              baseUrl: "nokia",
              id: 1100009,
            },
          })
        );
      } else {
        return res(
          ctx.delay(),
          ctx.errors([
            {
              message: "Invalid credentials",
            },
          ])
        );
      }
    }
  ),
  graphql.mutation<SsoLoginMutation, SsoLoginMutationVariables>(
    "ssoLogin",
    (req, res, ctx) => {
      // TODO: Check auth legitimacy somehow. Have a bunch of prepared session-token-cookies with different kinds of users and pick from them based on input. Return data based on inputs too.
      const sessionData = generateSessionData();

      createSession(req, res);
      const { session }: any = req;

      Object.keys(sessionData).forEach((key) => {
        session[key] = (sessionData as any)[key];
      });
      const stringSession = Buffer.from(JSON.stringify(session))
        .toString("base64")
        .replace("=", "");

      return res(
        ctx.cookie("session", stringSession),
        ctx.data({
          ssoLogin: {
            baseUrl: "nokia",
            id: 1100009,
          },
        })
      );
    }
  ),
  graphql.mutation<SignicatLoginMutation, SignicatLoginMutationVariables>(
    "signicatLogin",
    async (req, res, ctx) => {
      const { state, idToken } = req.variables;

      if (idToken && state) {
        if (req.cookies.session) {
          const token: {
            companyBaseUrl: string;
            companyData: any;
          } = jwtDecode(req.cookies.session, { header: true });
          const confirmationMode = state.includes("AUTHORIZED_ACTION");
          if (confirmationMode) {
            return res(
              ctx.data({
                signicatLogin: {
                  baseUrl: token.companyBaseUrl,
                  id: token.companyData.id,
                  meta: {
                    signicatAuthCallbackURL:
                      state?.split(";")[2] + "&" + state?.split(";")[3],
                  },
                },
              })
            );
          }
        } else {
          const stateParts = state?.split(";");
          const url = stateParts[2];
          const params = stateParts[3];

          const sessionData = generateSessionData();

          createSession(req, res);
          const { session }: any = req;

          Object.keys(sessionData).forEach((key) => {
            session[key] = (sessionData as any)[key];
          });
          const stringSession = Buffer.from(JSON.stringify(session))
            .toString("base64")
            .replace("=", "");

          return res(
            ctx.cookie("session", stringSession),
            ctx.data({
              signicatLogin: {
                baseUrl: "nokia",
                id: 1100009,
                meta: {
                  strongAuthFailed: false,
                  signicatAuthCallbackURL: params ? url + "&" + params : url,
                },
              },
            })
          );
        }
      }
    }
  ),
];
