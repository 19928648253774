const Input = {
  defaultProps: {
    size: "md",
    variant: "filled",
  },

  variants: {
    datePicker: {
      field: {
        border: 0,
        background: "transparent",
        color: "primary.color",
        borderColor: "transparent",
        fontWeight: "bold",
        _focus: {
          background: "transparent",
        },
        _hover: {
          background: "transparent",
        },
      },
    },
    filled: {
      field: {
        background: "input.background",
        color: "input.color",
        borderColor: "input.border",
        _focus: {
          background: "input.hoverBackground",
        },
        _hover: {
          background: "input.hoverBackground",
        },
      },
    },
    outline: {
      field: {
        borderColor: "input.border",
        color: "input.color",
      },
    },
  },
  baseStyle: {
    field: {
      color: "input.color",
    },
  },
};

export default Input;
