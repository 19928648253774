const Modal = {
  defaultProps: {},
  baseStyle: {
    dialog: {
      borderRadius: "lg",
    },
    header: {
      pr: "12",
    },
    closeButton: {},
  },
};

export default Modal;
