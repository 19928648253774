import { defineStyleConfig } from "@chakra-ui/react";

const EAIListItem = defineStyleConfig({
  variants: {
    alert: {
      background: "alert.background",
      color: "alert.color",
    },
    striped: {
      _odd: {
        backgroundColor: "table.oddBackground",
      },
      _even: {
        backgroundColor: "table.evenBackground",
      },
    },
  },
});

export default EAIListItem;
