import { get } from "lodash";
import { defineMessages } from "@formatjs/intl";

const languageMessages = defineMessages({
  en: {
    id: "common.language.en",
    defaultMessage: "English"
  },
  fi: {
    id: "common.language.fi",
    defaultMessage: "Finnish"
  },
  sv: {
    id: "common.language.sv",
    defaultMessage: "Swedish"
  },
  ru: {
    id: "common.language.ru",
    defaultMessage: "Russian"
  },
  de: {
    id: "common.language.de",
    defaultMessage: "German"
  },
  "zh-CN": {
    id: "common.language.zh-CN",
    defaultMessage: "Chinese"
  },
  "zh-TW": {
    id: "common.language.zh-TW",
    defaultMessage: "Taiwanese Mandarin"
  },
  fr: {
    id: "common.language.fr",
    defaultMessage: "French"
  },
  es: {
    id: "common.language.es",
    defaultMessage: "Spanish"
  },
  da: {
    id: "common.language.da",
    defaultMessage: "Danish"
  },
  pt: {
    id: "common.language.pt",
    defaultMessage: "Portuguese"
  },
  pl: {
    id: "common.language.pl",
    defaultMessage: "Polish"
  },
  et: {
    id: "common.language.et",
    defaultMessage: "Estonian"
  },
  nb: {
    id: "common.language.nb",
    defaultMessage: "Norwegian"
  },
  lt: {
    id: "common.language.lt",
    defaultMessage: "Lithuanian"
  },
  lv: {
    id: "common.language.lv",
    defaultMessage: "Latvian"
  },
  cs: {
    id: "common.language.cs",
    defaultMessage: "Czech"
  },
  sk: {
    id: "common.language.sk",
    defaultMessage: "Slovak"
  },
  nl: {
    id: "common.language.nl",
    defaultMessage: "Dutch"
  },
  th: {
    id: "common.language.th",
    defaultMessage: "Thai"
  },
  ko: {
    id: "common.language.ko",
    defaultMessage: "Korean"
  },
  ja: {
    id: "common.language.ja",
    defaultMessage: "Japanese"
  },
  hu: {
    id: "common.language.hu",
    defaultMessage: "Hungarian"
  },
  in: {
    id: "common.language.in",
    defaultMessage: "Indonesian"
  },
  sl: {
    id: "common.language.sl",
    defaultMessage: "Slovenian"
  },
});

export const getLanguageFriendlyName = (type: string) => {
  return get(
    languageMessages,
    type,
    {
      id: type,
      defaultMessage: type
    },
  );
};
