import { extendTheme } from "@chakra-ui/react";
import baseTheme from "./theme";
import { desaturate, lighten, linearGradient } from "polished";
import { merge } from "lodash-es";
import { ThemeName } from "apollo/generatedTypes";

// const baseTheme = clone(_baseTheme);

const palette = {
  defaultBlack: "#1B1B1B",
  defaultWhite: "#fff",
  defaultLightBlue: "#DFE9EE",
  defaultOrange: "#F07E2660",
  defaultViolet: "#D6C8E060",

  green: {
    950: "#0a100f",
    900: "#13201d",
    800: "#26403a",
    700: "#396057",
    600: "#4d8074",
    500: "#609f92",
    400: "#80b3a7",
    300: "#9fc6bd",
    200: "#bfd9d3",
    100: "#dfece9",
    50: "#eff5f4",
  },

  lightBlue: {
    950: "#080e11",
    900: "#111c22",
    800: "#223744",
    700: "#325367",
    600: "#436e89",
    500: "#548aab",
    400: "#76a1bc",
    300: "#98b9cd",
    200: "#bbd0dd",
    100: "#dde8ee",
    50: "#eef3f7",
  },

  deepBlue: {
    950: "#090f11",
    900: "#111d22",
    800: "#223a44",
    700: "#335766",
    600: "#447588",
    500: "#5592aa",
    400: "#77a8bb",
    300: "#99bdcc",
    200: "#bbd3dd",
    100: "#dde9ee",
    50: "#eef4f6",
  },

  brandBlue: {
    900: "#0c1931", // eclipse
    800: "#012858", // midnight
    700: "#06458A", // dawn
    600: desaturate(0.2, lighten(0.1, "#06458A")),
    500: desaturate(0.3, lighten(0.15, "#06458A")),
    400: "#4677B0", // steel
    300: "#749AC8", // iron
    200: "#9AC1F0", // mist
    100: "#CBE1FB", // cloud
    50: "#E6F5FD", //
  },

  brandGreen: {
    900: "#0c1931", // eclipse
    800: "#013958", // spruce
    700: "#06628A", // oak
    600: desaturate(0.2, lighten(0.1, "#06628A")),
    500: desaturate(0.3, lighten(0.15, "#06628A")),
    400: "#5D93AB", // pine
    300: "#8EB9C3", // haze
    200: "#B6D9D5", // spring
    100: "#DCEEE8", // dew
    50: "#F5F9F7", //
  },
};

const colors = merge({}, palette, baseTheme.colors);

const liftGradient = linearGradient({
  colorStops: [
    colors["blue"][800],
    colors["deepBlue"][200],
    colors["green"][100],
  ],
  toDirection: "to right",
});

const theme = extendTheme({
  ...baseTheme,

  config: {
    ...baseTheme.config,
    themeName: ThemeName.Allshares,
  },
  fonts: {
    heading: "GeneralSans-Regular",
    body: "GeneralSans-Regular",
  },
  colors: {
    ...colors,
    app: {
      background: "#F5F7F9",
    },
    login: {
      background: linearGradient({
        colorStops: ["#031653", "#94BAC9", "#DBECD9"],
        toDirection: "to right",
      }).backgroundImage,
      helpdeskBackground: linearGradient({
        colorStops: ["#D0DFE8", "#E0E9EE", "#CDDCE5"],
        toDirection: "to right",
      }).backgroundImage,
      helpdeskDisclaimerBackground: "transparent",
    },
    timelinePeriodColors: {
      // Short term (common)
      sharePurchasesEssp: palette["brandBlue"][500],
      sharePurchases: palette["brandBlue"][400],
      // Short term (rare)
      boardSetsEarningCriteria: palette["brandGreen"][500],
      dividendAdjustment: palette["brandBlue"][600],
      measureOfAchievement: palette["brandBlue"][500],
      shareDelivery: palette["brandBlue"][700],
      vestingPeriodRequirement: palette["brandBlue"][900],
      optionExpirationDate: palette["brandBlue"][800],
      optionDelivery: palette["brandGreen"][600],
      // Long term
      optionVestingPeriod: palette["brandBlue"][50],
      ownershipRequirement: palette["brandBlue"][100],
      performancePeriod: palette["brandGreen"][200],
      savings: palette["brandGreen"][100],
      subscriptionPeriod: palette["brandGreen"][100],
    },

    personnelFundGraphColors: {
      restricted: colors["brandBlue"][800],
      withdrawable: colors["brandBlue"][500],
      toBePaid: colors["brandBlue"][300],
      newInvestments: colors["brandBlue"][200],
      newTransfersWithdrawable: colors["brandBlue"][100],
      marketValue: colors["brandBlue"][700],
    },

    sidebarColors: {
      icon: "#08375C",
    },

    alert: {
      background: baseTheme.colors["white"],
      color: "black",
      headerColor: baseTheme.colors["white"],
      headerBackground: linearGradient({
        colorStops: [
          baseTheme.colors["orange"][500],
          lighten(0.2, baseTheme.colors["orange"][500]),
        ],

        toDirection: "to right",
      }).backgroundImage,
    },

    graph: {
      background: "white",
      color: "black",
      headerBackground: linearGradient({
        colorStops: [
          baseTheme.colors["gray"][200],
          lighten(0.1, baseTheme.colors["gray"][200]),
        ],
        toDirection: "to right",
      }).backgroundImage,
    },
    neutral: {
      background: baseTheme.colors["gray.50"],
      color: "black",
      headerBackground: linearGradient({
        colorStops: [
          colors["lightBlue"][200],
          lighten(0.1, colors["lightBlue"][200]),
        ],
        toDirection: "to right",
      }).backgroundImage,
    },
    success: {
      background: colors["green"][50],
      color: "black",
      headerBackground: linearGradient({
        colorStops: [colors["green"][200], lighten(0.1, colors["green"][200])],
        toDirection: "to right",
      }).backgroundImage,
    },

    secondary: {
      background: colors["gray"][200],
      color: "black",
      headerBackground: linearGradient({
        colorStops: [colors["gray"][200], lighten(0.1, colors["gray"][200])],
        toDirection: "to right",
      }).backgroundImage,
    },

    outline: {
      border: "1px",
      borderColor: colors["primary"]["dark"],
      color: colors["blue"][800],
      _hover: {
        background: "transparent",
      },
      _active: {
        color: colors["blue"][600],
      },
    },

    navbar: {
      background: colors["lightBlue"][200],
      color: colors["lightBlue"][950],
    },

    footer: {
      background: colors["lightBlue"][900],
      color: "primary.color",
    },

    brand: {
      highlight: colors["pink"][500],
      selected: colors["pink"][600],
      color: "black",
      background: colors["defaultWhite"],
      secondaryBackground: colors["blue"][500],
      tertiaryBackground: colors["defaultOrange"],

      sidebarHover: linearGradient({
        colorStops: [
          colors["deepBlue"][100],
          lighten(0.1, colors["deepBlue"][100]),
        ],
        toDirection: "to right",
      }).backgroundImage,

      sidebarActive: linearGradient({
        colorStops: [
          colors["deepBlue"][200],
          lighten(0.1, colors["deepBlue"][200]),
        ],
        toDirection: "to right",
      }).backgroundImage,

      heading1: colors["deepBlue"][700],
      dashboardWelcomeBox: {
        background: liftGradient?.backgroundImage,
        color: "white",
      },
    },

    input: {
      background: baseTheme.colors["white"],
      border: colors["gray"][200],
      color: "black",
      hoverBackground: colors["gray"][50],
    },
    link: colors["blue"][800],

    navigationButton: {
      color: "white",
      background: colors["blue"][800],
      hoverBackground: colors["blue"][700],
      borderRadius: "full",
      borderWidth: "1px",
      borderColor: "transparent",
      textTransform: "none",
      _active: {
        bg: colors["blue"][900],
      },
    },
    inActiveNavigationButton: {
      color: "black",
      background: colors["deepBlue"][100],
      hoverBackground: colors["deepBlue"][300],
      borderRadius: "full",
      borderWidth: "1px",
      borderColor: colors["deepBlue"][200],
      textTransform: "none",
      _active: {
        bg: colors["deepBlue"][500],
      },
    },
    table: {
      subHeader: colors["blue"][50],
      evenBackground: colors["gray"][50],
      oddBackground: colors["white"],
      rowBorder: colors["gray"][300],
      footerBorder: colors["blue"][800],
      hover: colors["whiteAlpha"][700],
      header: colors["blue"][300],
      selectedBackground: linearGradient({
        colorStops: [
          colors["gray"][100],
          colors["gray"][50],
          colors["gray"][100],
        ],
        toDirection: "to right",
      }).backgroundImage,
      alertBackground: colors["deepBlue"][100],
      activeBackground: colors["gray"][100],
      summaryBackground: colors["gray"][200],
      summaryColor: colors["black"],
      color: colors["black"],
    },

    multiselect: {
      container: {
        border: "2px solid",
        background: "transparent",
        borderColor: "input.border",
      },
      valueContainer: {
        background: "input.background",
        paddingLeft: 2,
        width: "100%",
        minHeight: "40px",
      },
      control: {
        border: "2px solid",
        borderColor: "input.border",
        borderRadius: 6,
      },
      menu: {
        zIndex: 2,
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
        mt: "2px",
      },
      dropdownIndicator: {
        background: "white",
      },
    },
  },
});

export type AllSharesTheme = typeof theme;

export default theme;
