const Badge = {
  variants: {
    bluePill: {
      color: "badgeColors.bluePill.color",
      backgroundColor: "badgeColors.bluePill.background",
      borderRadius: "16",
      textTransform: "capitalize",
      px: "4",
      py: "1",
      mb: "1",
      mr: "1",
    },
    greenPill: {
      color: "badgeColors.greenPill.color",
      backgroundColor: "badgeColors.greenPill.background",
      borderRadius: "16",
      textTransform: "capitalize",
      px: "4",
      py: "1",
      mb: "1",
      mr: "1",
    },
    violetPill: {
      color: "badgeColors.violetPill.color",
      backgroundColor: "badgeColors.violetPill.background",
      borderRadius: "16",
      textTransform: "capitalize",
      px: "4",
      py: "1",
      mb: "1",
      mr: "1",
    },
    redPill: {
      color: "badgeColors.redPill.color",
      backgroundColor: "badgeColors.redPill.background",
      borderRadius: "16",
      textTransform: "capitalize",
      px: "4",
      py: "1",
      mb: "1",
      mr: "1",
    },
    yellowPill: {
      color: "badgeColors.yellowPill.color",
      backgroundColor: "badgeColors.yellowPill.background",
      borderRadius: "16",
      textTransform: "capitalize",
      px: "4",
      py: "1",
      mb: "1",
      mr: "1",
    },
  },
};

export default Badge;
