import { defineStyleConfig } from "@chakra-ui/react";

const EaiCard = defineStyleConfig({
  variants: {
    primary: {
      bg: "brand.background",
      color: "brand.color",
    },

    secondary: {
      bg: "secondary.background",
      color: "secondary.color",
    },
    warning: {
      bg: "warning.background",
      color: "warning.color",
    },
    success: {
      bg: "success.background",
      color: "success.color",
    },
    alert: {
      bg: "alert.background",
      color: "alert.color",
    },
    graph: {
      bg: "white",
      color: "black",
    },
    neutral: {
      bg: "neutral.background",
      color: "black",
    },
  },
  baseStyle: {
    borderRadius: 6,
    overflow: "hidden",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
  },
  defaultProps: {
    variant: "primary",
  },
});

export const EaiCardHeader = defineStyleConfig({
  variants: {
    primary: {
      // Give meaningful names to the variants, describing their purpose
      bg: "brand.background",
      color: "brand.color",
      borderBottomColor: "gray.300",
    },
    secondary: {
      bg: "secondary.headerBackground",
      color: "black",
      borderBottomColor: "gray.300",
    },
    warning: {
      bg: "red.200",
      color: "blackAlpha.900",
    },
    success: {
      bg: "success.headerBackground",
      color: "success.color",
    },
    alert: {
      bg: "alert.headerBackground",
      color: "alert.headerColor",
    },

    neutral: {
      bg: "neutral.headerBackground",
      color: "neutral.color",
    },

    graph: {
      bg: "graph.headerBackground",
      color: "graph.color",
    },

    demo: {
      bg: "orange.200",
      color: "blackAlpha.900",
    },
  },
  baseStyle: {
    py: 4,
    px: 8,

    borderTopRadius: 6,
    alignItems: "center",
    fontWeight: "bold",
    borderBottom: "1px solid",
    borderBottomColor: "gray.300",
  },
});

export default EaiCard;
