const Banner = {
  baseStyle: {
    img: {
      width: "100%",
      height: "auto",
    },
  },
};

export default Banner;
