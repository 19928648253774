import {
  Alert,
  Box,
  Code,
  Heading,
  Spacer,
  HStack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import CopyToClipBoardButton from "pages/components/CopyToClipBoardButton";
import ErrorStackParser from "error-stack-parser";

class ErrorBoundary extends React.Component<{ children: React.ReactNode }> {
  state: {
    hasError: boolean;
    error: Error | null;
    errorInfo: React.ErrorInfo | null;
  } = {
    hasError: false,
    error: null,
    errorInfo: null,
  };
  constructor(props: { children: React.ReactNode }) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, error: null, errorInfo: null };
  }
  static getDerivedStateFromError(/*error*/) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can use your own error logging service here
    // @ts-ignore
    // console.log("ERROR TRACED ERROR INFO", ErrorStackParser.parse(errorInfo));

    this.setState({ error, errorInfo });
  }
  render() {
    const errorsToJSON = (
      error: Error | null,
      errorInfo: React.ErrorInfo | null
    ) => {
      if (error)
        return JSON.stringify(
          {
            url: window.location.href,
            error: error?.toString(),
            stackTrace: ErrorStackParser.parse(error),
            errorInfo: errorInfo?.componentStack,
          },
          null,
          4
        );
    };

    // Check if the error is thrown
    if (this.state.hasError) {
      console.log(
        "ERROR TO JJSON",
        this.state.hasError &&
          errorsToJSON(this.state?.error, this.state?.errorInfo)
      );
      // You can render any custom fallback UI
      return (
        <Box background="white" padding="4" borderRadius="6">
          <Heading as="h2" color="brand.heading1" py="2">
            Something went wrong
          </Heading>
          <Spacer height="4" />
          <HStack spacing="4" alignItems="center">
            <Text>
              You can copy the error to clipboard and send it to support.
            </Text>

            <CopyToClipBoardButton
              colorScheme="blue"
              size="xs"
              isDisabled={!this.state.error}
              // @ts-ignore
              value={
                this.state?.error
                  ? errorsToJSON(this.state.error, this.state.errorInfo)
                  : ""
              }
            />
          </HStack>

          <Spacer height="6" />
          <Alert status="error" flexDirection="column" alignItems="flex-start">
            <Text fontWeight="bold">{this.state.error?.toString()}</Text>
          </Alert>

          <Spacer height="6" />
          <Code>URL: {window.location.href}</Code>

          <Spacer height="6" />
          <Code>
            {this.state?.error &&
              JSON.stringify(
                ErrorStackParser.parse(this.state?.error),
                null,
                2
              )}
          </Code>

          <Spacer height="6" />
          <Code>{JSON.stringify(this.state.errorInfo, null, 4)}</Code>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
